<template>
  <div>
    <b-card-code
      :title="'Add Translation To Accessory: ' + nameCat"
      :before-change="validationForm"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-row>
                <!-- translation language -->
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="language"
                    rules="required"
                  >
                    <b-form-group
                      label="Language"
                      label-for="language"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        :id="'language'"
                        v-model="tranLocaleSelect"
                        :state="tranLocaleSelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="localeOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- translation name -->
                <b-col md="6">
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        :id="'TranslationName'"
                        v-model="translations.name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-button variant="primary" @click.prevent="validationForm"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>

import { heightTransition } from "@core/mixins/ui/transition";
import { required, integer } from "@validations";
export default {

  mixins: [heightTransition],
  data() {
    return {
      errors_back: [],
      showDismissibleAlert: false,
      required,
      integer,
      id: 0,
      translations: {
        locale: "",
        name: "",
      },
      tranLocaleSelect: null,
      localeOptions: [
        {
          value: "en",
          text: "English",
        },
        {
          value: "ar",
          text: "Arabic",
        },
      ],
      nameCat: "",
    };
  },
  methods: {
    // submit Trans
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.translations.locale = this.tranLocaleSelect.value;
          const url = this.$session.get("lang");
          axios
            .post(
              "accessories/" + this.id + "/translations/store",
              this.translations
            )
            .then((result) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "Your work has been saved",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.back(1);
            })
            .catch((err) => {
              this.$swal({
                position: "center",
                icon: "error",
                title: "Error!",
                showConfirmButton: false,
                timer: 1500,
              });
              this.errors_back.length = 0;
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  });
                } else {
                  this.errors_back = err.response.data.data;
                }
                this.showDismissibleAlert = true;
              } else {
                this.errors_back = [];
                this.errors_back.push({
                  error: "internal server error",
                });
                this.showDismissibleAlert = true;
              }
            });
        }
      });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
  created() {
    this.id = this.$route.params.id;
    const url = this.$session.get("lang");
    axios
      .get("accessories/" + this.id)
      .then((result) => {
        const data = result.data.data;
        this.nameCat = data.translation[0].name;
      })
      .catch((err) => {
        this.errors_back.length = 0;
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            });
          } else {
            this.errors_back = err.response.data.data;
          }
          this.showDismissibleAlert = true;
        } else {
          this.errors_back = [];
          this.errors_back.push({
            error: "internal server error",
          });
          this.showDismissibleAlert = true;
        }
      });
  },
};
</script>

<style lang="scss"></style>
